import styled from "styled-components";

export const Wrapper = styled.div`
  text-align: center;
`;

export const Heading = styled.h4`
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 16px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
`;
