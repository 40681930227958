import styled from "styled-components";
import { RotaCheckbox, RotaButtonNew } from "@teamrota/rota-design";

export const InputContainer = styled.div`
  > div:first-of-type {
    margin: 12px 0 30px;
  }
`;

export const InputRow = styled.div`
  display: grid;
  grid-template-columns: 45% 30% 3%;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 42px !important;
`;

export const StyledButton = styled(RotaButtonNew)`
  margin-bottom: 42px;
`;

export const StyledCheckbox = styled(RotaCheckbox)`
  display: block !important;
  > label {
    justify-content: left !important;
  }
`;
