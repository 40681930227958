import React from "react";
import { RotaButtonNew } from "@teamrota/rota-design";
import { Variant } from "@teamrota/rota-design/dist/components/button";
import { ButtonGroup } from "./styles";

interface SaveButtonsProps {
  action: string;
  isEnabled: boolean;
  onSave: (saveAndAdd: boolean) => void;
}

const SaveButtons = ({ action, isEnabled, onSave }: SaveButtonsProps) => {
  return (
    <ButtonGroup>
      {action === "Create" && (
        <RotaButtonNew
          variant={Variant.outlined}
          disabled={!isEnabled}
          onClick={() => onSave(true)}
        >
          Save and Add
        </RotaButtonNew>
      )}
      <RotaButtonNew disabled={!isEnabled} onClick={() => onSave(false)}>
        Done
      </RotaButtonNew>
    </ButtonGroup>
  );
};

export default SaveButtons;
