import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";
import { PayrollBreak } from "../types";

export const CREATE_PAYROLL_BREAK = addAuthVars(gql`
  mutation createPayrollBreak($name: String!, $config: [JSONObject]!) {
    createPayrollBreak(name: $name, config: $config) {
      name
      config
    }
  }
`);

export const useCreatePayrollBreak = () => {
  const [create, { loading, error }] = useMutation(CREATE_PAYROLL_BREAK, {
    onError: error => {
      throw error;
    }
  });
  const createPayrollBreak = async ({ name, config }: PayrollBreak) => {
    await create({
      variables: {
        name,
        config
      },
      refetchQueries: ["payrollBreaks"]
    });
  };

  return { createPayrollBreak, loading, error };
};
