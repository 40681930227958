import React, { useState, useEffect, useMemo } from "react";
import {
  ModalHeader,
  RotaNewModal,
  RotaButtonIcon,
  RotaInputNew,
  iconNew as RotaIcon,
  useToast
} from "@teamrota/rota-design";
import RateRulesForm from "./components/RateRulesForm";
import CancelModal from "../CancelModal";
import SaveButtons from "../SaveButtons";
import useRates from "./hooks/use-rates";
import { Props, RateRule, StringMap, BooleanMap } from "./types";
import {
  formatRates,
  isNameTaken,
  isRateRulesValid,
  isRateRulesModified,
  isRateRulesFilled,
  validateFields
} from "./utils";
import { InputContainer } from "./styles";

const { Cancel } = RotaIcon;

const CreateEditRatesModal: React.FC<React.PropsWithChildren<Props>> = ({
  action,
  allSavedRates,
  initialRates,
  onClose,
  onSave
}) => {
  const {
    name,
    setName,
    payRates,
    multiplierRates,
    resetRates,
    handleAddRate,
    handleUpdateRate,
    handleDeleteRate
  } = useRates(initialRates);

  const [nameError, setNameError] = useState<string>("");
  const [ruleErrors, setRuleErrors] = useState<StringMap>({});
  const [isAddMore, setIsAddMore] = useState<BooleanMap>({});
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<BooleanMap>({});

  const { showToast } = useToast();

  const rates = [...payRates, ...multiplierRates];

  const isModified = useMemo(
    () => isRateRulesModified(name, rates, initialRates),
    [name, rates, initialRates]
  );

  useEffect(() => {
    const {
      isNameValid,
      isPayValid,
      isMultiplierValid,
      isRatesValid
    } = isRateRulesValid(name, payRates, multiplierRates, ruleErrors);

    setIsAddMore(prevState => ({
      ...prevState,
      payRates: isPayValid,
      multiplierRates: isMultiplierValid
    }));
    setIsSaveEnabled(isNameValid && isRatesValid && isModified);
  }, [name, payRates, multiplierRates, ruleErrors, isModified]);

  const handleDropdownToggle = (key: string) => {
    setIsDropdownOpen(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const handleInputChange = async (
    index: number,
    field: keyof RateRule,
    value: string,
    isPayRate: boolean
  ) => {
    handleUpdateRate(index, field, value, isPayRate);
    setRuleErrors(prevErrors => ({
      ...prevErrors,
      [`${field}-${index}`]: validateFields(field, value)
    }));
  };

  const handleSave = async (saveAndAdd: boolean) => {
    if (isNameTaken(name, initialRates, allSavedRates)) {
      setNameError("Name already taken. Choose another.");
      showToast("Correct all errors before saving.", { severity: "error" });
      return;
    }

    const config = formatRates(rates);
    try {
      await onSave({ name, config });
      showToast("Record saved.", { severity: "success" });
      if (saveAndAdd) {
        setName("");
        resetRates();
      } else onClose();
    } catch (e) {
      showToast(`Error: ${(e as Error).message}`, { severity: "error" });
    }
  };

  const handleCloseModal = () => {
    if (
      (action === "Create" && isRateRulesFilled(name, rates)) ||
      (action === "Edit" && isModified)
    ) {
      setIsCancelModalOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <RotaNewModal
      onClose={handleCloseModal}
      size="medium"
      header={
        <ModalHeader
          title={`${action} Pay`}
          endAction={
            <RotaButtonIcon onClick={handleCloseModal}>
              <Cancel />
            </RotaButtonIcon>
          }
        />
      }
    >
      <div style={{ position: "relative" }}>
        <InputContainer>
          <RotaInputNew
            label="Name"
            placeholder="Enter a name"
            helperText="Please provide a name"
            value={name}
            onChange={({ target: { value } }) => {
              setName(value);
              setNameError("");
            }}
            isError={!!nameError}
            errorMessage={nameError}
          />
          <RateRulesForm
            isPayRate={true}
            rates={payRates}
            resetRates={resetRates}
            errors={ruleErrors}
            isAddMore={!!isAddMore.payRates}
            isDropdownOpen={isDropdownOpen}
            onDropdownToggle={handleDropdownToggle}
            onInputChange={handleInputChange}
            onAddRate={handleAddRate}
            onDeleteRate={handleDeleteRate}
          />
          <RateRulesForm
            isPayRate={false}
            rates={multiplierRates}
            resetRates={resetRates}
            errors={ruleErrors}
            isAddMore={!!isAddMore.multiplierRates}
            isDropdownOpen={isDropdownOpen}
            onDropdownToggle={handleDropdownToggle}
            onInputChange={handleInputChange}
            onAddRate={handleAddRate}
            onDeleteRate={handleDeleteRate}
          />
        </InputContainer>

        <SaveButtons
          action={action}
          isEnabled={isSaveEnabled}
          onSave={handleSave}
        />
      </div>

      {isCancelModalOpen && (
        <CancelModal
          onCancel={() => setIsCancelModalOpen(false)}
          onClose={onClose}
        />
      )}
    </RotaNewModal>
  );
};

export default CreateEditRatesModal;
