import { useState, useEffect } from "react";
import { BreakRule, Break } from "../types";
import { convertBreakConfigToRules } from "../utils";

const useBreaks = (initialBreaks?: Break) => {
  const [name, setName] = useState<string>("");
  const [breaks, setBreaks] = useState<BreakRule[]>([]);

  const defaultBreaks: BreakRule[] = [
    {
      startDay: "",
      endDay: "",
      value: "",
      hours: "",
      shiftType: "",
      deduct: ""
    }
  ];

  useEffect(() => {
    if (initialBreaks?.config?.length) {
      setName(initialBreaks.name);
      setBreaks(convertBreakConfigToRules(initialBreaks.config));
    } else setBreaks(defaultBreaks);
  }, []);

  const resetBreaks = () => setBreaks(defaultBreaks);

  const handleAddBreak = () => {
    setBreaks([...breaks, ...defaultBreaks]);
  };

  const handleUpdateBreak = (
    index: number,
    field: keyof BreakRule,
    value: string
  ) => {
    const updatedBreaks = [...breaks];
    updatedBreaks[index][field] = value;

    setBreaks(updatedBreaks);
  };

  const handleDeleteBreak = (index: number) => {
    const updatedBreaks = breaks.filter((_, i) => i !== index);
    setBreaks(updatedBreaks);
  };

  return {
    name,
    setName,
    breaks,
    resetBreaks,
    handleAddBreak,
    handleUpdateBreak,
    handleDeleteBreak
  };
};

export default useBreaks;
