import React from "react";
import {
  RotaInputNew,
  DropdownSelectNew,
  RotaButtonNew,
  iconNew as RotaIcon
} from "@teamrota/rota-design";
import { Variant } from "@teamrota/rota-design/dist/components/button";
import { BreakRulesFormProps, BreakRule } from "../types";
import { dayOptions, shiftTypeOptions, valueOptions } from "../utils";
import { InputRow } from "../styles";

const { Bin } = RotaIcon;

const BreakRulesForm = ({
  breaks,
  errors,
  isAddMore,
  isDropdownOpen,
  onInputChange,
  onDropdownToggle,
  onAddBreak,
  onDeleteBreak
}: BreakRulesFormProps) => {
  return (
    <>
      {breaks.map((rule: BreakRule, index: number) => (
        <InputRow key={index}>
          <DropdownSelectNew
            labelText="Start day"
            placeholderText="Select a day"
            options={dayOptions}
            selectedValue={rule.startDay}
            isOpen={isDropdownOpen[`startDay-${index}`]}
            setIsOpen={() => onDropdownToggle(`startDay-${index}`)}
            onSelectOption={value => onInputChange(index, "startDay", value)}
          />
          <DropdownSelectNew
            labelText="End day"
            placeholderText="Select a day"
            options={dayOptions}
            selectedValue={rule.endDay}
            isOpen={isDropdownOpen[`endDay-${index}`]}
            setIsOpen={() => onDropdownToggle(`endDay-${index}`)}
            onSelectOption={value => onInputChange(index, "endDay", value)}
            isError={!!errors[`endDay-${index}`]}
            errorMessage={errors[`endDay-${index}`]}
          />
          <DropdownSelectNew
            labelText="Value"
            placeholderText="Please select"
            options={valueOptions}
            selectedValue={rule.value}
            isOpen={isDropdownOpen[`value-${index}`]}
            setIsOpen={() => onDropdownToggle(`value-${index}`)}
            onSelectOption={value => onInputChange(index, "value", value)}
          />
          <RotaInputNew
            label="Hours"
            placeholder="Enter shift length"
            helperText="Please enter length of shift in hours"
            value={rule.hours}
            onChange={e => onInputChange(index, "hours", e.target.value)}
            isError={!!errors[`hours-${index}`]}
            errorMessage={errors[`hours-${index}`]}
          />
          <DropdownSelectNew
            labelText="Shift type"
            placeholderText="Select type"
            options={shiftTypeOptions}
            selectedValue={rule.shiftType}
            isOpen={isDropdownOpen[`shiftType-${index}`]}
            setIsOpen={() => onDropdownToggle(`shiftType-${index}`)}
            onSelectOption={value => onInputChange(index, "shiftType", value)}
          />
          <RotaInputNew
            label="Deduct"
            placeholder="Enter break"
            helperText="Please enter break deduction in hours"
            value={rule.deduct}
            onChange={e => onInputChange(index, "deduct", e.target.value)}
            isError={!!errors[`deduct-${index}`]}
            errorMessage={errors[`deduct-${index}`]}
          />
          {breaks.length > 1 && <Bin onClick={() => onDeleteBreak(index)} />}
        </InputRow>
      ))}

      <RotaButtonNew
        variant={Variant.outlined}
        disabled={!isAddMore}
        onClick={onAddBreak}
      >
        Add another
      </RotaButtonNew>
    </>
  );
};

export default BreakRulesForm;
