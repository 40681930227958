import React, { useState, useEffect } from "react";
import {
  RotaInputNew,
  DropdownSelectNew,
  iconNew as RotaIcon
} from "@teamrota/rota-design";
import { Variant } from "@teamrota/rota-design/dist/components/button";
import { RatesRulesFormProps } from "../types";
import { rateNameOptions } from "../utils";
import { InputRow, StyledCheckbox, StyledButton } from "../styles";

const { Bin } = RotaIcon;

const RateRulesForm = ({
  isPayRate,
  rates,
  resetRates,
  errors,
  isAddMore,
  isDropdownOpen,
  onDropdownToggle,
  onInputChange,
  onAddRate,
  onDeleteRate
}: RatesRulesFormProps) => {
  const [isShowForm, setIsShowForm] = useState<boolean>(false);

  useEffect(() => {
    setIsShowForm(rates.length > 0);
  }, [rates]);

  const checkboxLabel = isPayRate ? "Add Pay Rates" : "Add Multiplier Rates";
  const labelText = isPayRate ? "Pay rate" : "Multiplier rate";
  const placeholderText = isPayRate
    ? "Select a fixed pay rate"
    : "Select a multiplier rate";
  const helperText = isPayRate
    ? "Please enter the numerical rate to 2 decimal places"
    : "Please enter the numerical rate to 3 decimal places";
  const fieldKey = isPayRate ? "amount" : "factor";

  return (
    <div>
      <StyledCheckbox
        label={checkboxLabel}
        isChecked={isShowForm}
        onClick={e => {
          const checked = e.target.checked;
          setIsShowForm(checked);
          if (!checked) resetRates(isPayRate ? "pay" : "multiplier");
          else onAddRate(isPayRate);
        }}
      />
      {isShowForm && (
        <div>
          {rates.map((rule, index) => (
            <InputRow key={index}>
              <DropdownSelectNew
                labelText={labelText}
                placeholderText={placeholderText}
                options={rateNameOptions}
                selectedValue={rule.name}
                isOpen={isDropdownOpen[`${fieldKey}-name-${index}`]}
                setIsOpen={() => onDropdownToggle(`${fieldKey}-name-${index}`)}
                onSelectOption={value =>
                  onInputChange(index, "name", value, isPayRate)
                }
              />
              <RotaInputNew
                label="Rate"
                placeholder="Enter a value in numbers"
                helperText={helperText}
                iconLeft={isPayRate ? "£" : "x"}
                value={rule[fieldKey] as string}
                onChange={e =>
                  onInputChange(index, fieldKey, e.target.value, isPayRate)
                }
                isError={!!errors[`${fieldKey}-${index}`]}
                errorMessage={errors[`${fieldKey}-${index}`]}
              />
              <Bin onClick={() => onDeleteRate(index, isPayRate)} />
            </InputRow>
          ))}
          <StyledButton
            variant={Variant.outlined}
            disabled={!isAddMore}
            onClick={() => onAddRate(isPayRate)}
          >
            Add another
          </StyledButton>
        </div>
      )}
    </div>
  );
};

export default RateRulesForm;
