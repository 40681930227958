import { capitalize } from "lodash/fp";
import { BreakRule, Break, StringMap } from "./types";

const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

export const dayOptions = [
  { label: "Monday", value: "mon" },
  { label: "Tuesday", value: "tue" },
  { label: "Wednesday", value: "wed" },
  { label: "Thursday", value: "thu" },
  { label: "Friday", value: "fri" },
  { label: "Saturday", value: "sat" },
  { label: "Sunday", value: "sun" }
];

export const valueOptions = [
  { label: "More than", value: "more_than" },
  { label: "Less than", value: "less_than" }
];

export const shiftTypeOptions = [
  { label: "Day", value: "day" },
  { label: "Night", value: "night" }
];

export const getDaysBetween = (startDay: string, endDay: string) => {
  const startIndex = daysOfWeek.indexOf(startDay);
  const endIndex = daysOfWeek.indexOf(endDay);

  return daysOfWeek
    .slice(startIndex, endIndex + 1)
    .map(day => day.toUpperCase());
};

export const convertBreakConfigToRules = (config: Break["config"]) =>
  config.map(({ conditions, event }) => {
    const days = conditions["shift.shiftStart.dayOfWeek in"] ?? [];
    const durationKey =
      "shift.shiftDuration >=" in conditions
        ? "shift.shiftDuration >="
        : "shift.shiftDuration <=";
    const duration = conditions[durationKey];
    const type = conditions["shift.type ="];

    return {
      startDay: days.length > 0 ? days[0].toLocaleLowerCase() : "",
      endDay: days.length > 0 ? days.slice(-1)[0].toLocaleLowerCase() : "",
      value: durationKey.includes(">=") ? "more_than" : "less_than",
      hours: duration ? String(duration / 60) : "",
      shiftType: type ? type.toLocaleLowerCase() : "",
      deduct: String(event.params.set / 60)
    };
  });

export const convertBreakRulesToConfig = (breaks: BreakRule[]) => {
  return breaks.map((rule, index) => ({
    name: `RULE${index + 1}`,
    type: "BREAK",
    event: {
      type: "break",
      params: {
        set: parseFloat(rule.deduct) * 60
      }
    },
    conditions: {
      "shift.type =": rule.shiftType,
      [`shift.shiftDuration ${rule.value === "more_than" ? ">=" : "<="}`]:
        parseFloat(rule.hours) * 60,
      "shift.shiftStart.dayOfWeek in": getDaysBetween(
        rule.startDay,
        rule.endDay
      )
    }
  }));
};

export const isBreakRulesFilled = (name: string, breaks: BreakRule[]) => {
  return (
    name.trim() !== "" ||
    breaks.some(rule => Object.values(rule).some(value => value !== ""))
  );
};

export const isBreakRulesValid = (
  name: string,
  breaks: BreakRule[],
  errors: StringMap
) => {
  const isNameValid = name.trim() !== "";
  const isRuleValid = breaks.every(rule => Object.values(rule).every(Boolean));
  const isErrors = Object.values(errors).some(Boolean);

  return {
    isNameValid,
    isBreaksValid: isRuleValid && !isErrors
  };
};

export const isBreakRulesModified = (
  name: string,
  breaks: BreakRule[],
  initialBreaks: Break | undefined
) => {
  if (!initialBreaks) return true;

  const currentRules = breaks;
  const initialRules = convertBreakConfigToRules(initialBreaks.config);

  const isNameSame = name === initialBreaks?.name;
  const isBreaksSame =
    JSON.stringify(currentRules) === JSON.stringify(initialRules);

  return !isNameSame || !isBreaksSame;
};

export const isNameTaken = (
  name: string,
  initialBreaks: Break | undefined,
  allSavedBreaks: Break[]
) =>
  allSavedBreaks.some(
    (br: Break) =>
      br.name.toLowerCase() === name.toLowerCase().trim() &&
      br.name.toLowerCase() !== initialBreaks?.name.toLowerCase()
  );

export const validateFields = (
  breaks: BreakRule,
  field: keyof BreakRule,
  value: string
) => {
  let error = "";

  const { startDay, endDay } = breaks;
  const isValidNumber = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;

  switch (field) {
    case "startDay":
    case "endDay":
      if (startDay && endDay) {
        const startIndex = daysOfWeek.indexOf(startDay);
        const endIndex = daysOfWeek.indexOf(endDay);

        if (endIndex < startIndex) {
          error = "Must be after or same as start day.";
        }
      }
      break;

    case "hours":
    case "deduct":
      if (value && !isValidNumber.test(value)) {
        error = "Enter a valid number.";
      } else if (parseFloat(value) <= 0) {
        error = `${capitalize(field)} must be a positive number.`;
      } else if (field === "hours" && value.split(".")[1]?.length > 2) {
        error = "Hours cannot exceed 2 decimal places.";
      } else if (field === "deduct" && value.split(".")[1]?.length > 3) {
        error = "Deduct cannot exceed 3 decimal places.";
      }
      break;
  }

  return error;
};
