import styled from "styled-components";

export const InputContainer = styled.div`
  > div {
    margin: 12px 0 42px;
  }
`;

export const InputRow = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr) 32px;
  align-items: center;
  column-gap: 16px;
`;
