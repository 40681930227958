import { useState, useEffect } from "react";
import { RateRule, Rate, RateType } from "../types";
import { getRates } from "../utils";

const useRates = (initialRates?: Rate) => {
  const [name, setName] = useState<string>("");
  const [payRates, setPayRates] = useState<RateRule[]>([]);
  const [multiplierRates, setMultiplierRates] = useState<RateRule[]>([]);

  const defaultPayRate = [{ name: "", amount: "" }];
  const defaultMultiplierRate = [{ name: "", factor: "" }];

  useEffect(() => {
    if (initialRates?.config?.length) {
      setName(initialRates.name);
      setPayRates(getRates(initialRates.config, "amount"));
      setMultiplierRates(getRates(initialRates.config, "factor"));
    } else resetRates();
  }, []);

  const resetRates = (type: RateType = "all") => {
    if (type === "pay") setPayRates([]);
    else if (type === "multiplier") setMultiplierRates([]);
    else {
      setPayRates([]);
      setMultiplierRates([]);
    }
  };

  const handleAddRate = (isPayRate: boolean) => {
    if (isPayRate) setPayRates([...payRates, ...defaultPayRate]);
    else setMultiplierRates([...multiplierRates, ...defaultMultiplierRate]);
  };

  const handleUpdateRate = (
    index: number,
    field: keyof RateRule,
    value: string,
    isPayRate: boolean
  ) => {
    const updatedRates = isPayRate ? [...payRates] : [...multiplierRates];
    updatedRates[index][field] = value;

    if (isPayRate) setPayRates(updatedRates);
    else setMultiplierRates(updatedRates);
  };

  const handleDeleteRate = (index: number, isPayRate: boolean) => {
    const updatedRates = (rates: RateRule[]) =>
      rates.filter((_, i) => i !== index);

    isPayRate ? setPayRates(updatedRates) : setMultiplierRates(updatedRates);
  };

  return {
    name,
    setName,
    payRates,
    multiplierRates,
    resetRates,
    handleAddRate,
    handleUpdateRate,
    handleDeleteRate
  };
};

export default useRates;
