import { capitalize } from "lodash/fp";
import { Rate, RateRule, StringMap } from "./types";

export const rateNameOptions = [
  { label: "Day Shift (Mon-Sat)", value: "DAYSHIFTMONSAT" },
  { label: "Day Shift (Sunday)", value: "DAYSHIFTSUNDAY" },
  { label: "Night Shift (Mon-Sat)", value: "NIGHTSHIFTMONSAT" },
  { label: "Night Shift (Sunday)", value: "NIGHTSHIFTSUNDAY" },
  { label: "Saturday Premium", value: "SATURDAYPREMIUM" },
  { label: "Sleepover Hours", value: "SLEEPOVERHOURS" },
  { label: "Twilight (Mon-Sat)", value: "TWILIGHTMONSAT" },
  { label: "Twilight (Sunday)", value: "TWILIGHTSUNDAY" },
  { label: "Bank Holiday", value: "BANKHOL" },
  { label: "Bank Holiday (Night)", value: "BANKHOLNIGHT" },
  { label: "Bank Holiday (Twilight)", value: "BANKHOLTW" },
  { label: "Christmas/New Year Day Shift", value: "XMAS/NYDAYSHIFT" },
  { label: "Christmas/New Year Night Shift", value: "XMAS/NYNIGHTSHIFT" },
  { label: "Christmas/New Year Twilight", value: "XMAS/NYTW" }
];

export const getRates = (
  rates: Rate["config"],
  key: keyof Rate["config"][0]
) => {
  return rates
    .filter(rate => rate[key] !== undefined)
    .map(rate => ({
      name: rate.name ?? "",
      [key]: String(rate[key]) ?? ""
    }));
};

export const formatRates = (rates: RateRule[]) => {
  return rates.map(rate => ({
    ...rate,
    amount: rate.amount !== undefined ? parseFloat(rate.amount) : undefined,
    factor: rate.factor !== undefined ? parseFloat(rate.factor) : undefined
  }));
};

export const normalizeRates = (rates: Rate["config"]) => {
  return rates.map(rate => ({
    ...rate,
    amount: rate.amount !== undefined ? String(rate.amount) : undefined,
    factor: rate.factor !== undefined ? String(rate.factor) : undefined
  }));
};

export const isNameTaken = (
  name: string,
  initialRates: Rate | undefined,
  allSavedRates: Rate[]
) =>
  allSavedRates.some(
    (br: Rate) =>
      br.name.toLowerCase() === name.toLowerCase().trim() &&
      br.name.toLowerCase() !== initialRates?.name.toLowerCase()
  );

export const isValid = (rates: RateRule[]) => {
  return (
    !!rates.length && rates.every(rate => Object.values(rate).every(Boolean))
  );
};

export const isErrors = (errors: StringMap, value: string) => {
  return Object.entries(errors).some(
    ([key, val]) => key.includes(value) && Boolean(val)
  );
};

export const isRateRulesValid = (
  name: string,
  payRates: RateRule[],
  multiplierRates: RateRule[],
  errors: StringMap
) => {
  const isNameValid = name.trim() !== "";
  const isPayValid = isValid(payRates) && !isErrors(errors, "amount");
  const isMultiplierValid =
    isValid(multiplierRates) && !isErrors(errors, "factor");

  const isRatesValid =
    payRates.length > 0 && multiplierRates.length > 0
      ? isPayValid && isMultiplierValid
      : isPayValid || isMultiplierValid;

  return { isNameValid, isPayValid, isMultiplierValid, isRatesValid };
};

export const isRateRulesModified = (
  name: string,
  rates: RateRule[],
  initialRates: Rate | undefined
) => {
  if (!initialRates) return true;

  const currentRules = rates;
  const initialRules = normalizeRates(initialRates.config);

  const isNameSame = name === initialRates.name;
  const isRatesSame =
    JSON.stringify(currentRules) === JSON.stringify(initialRules);

  return !isNameSame || !isRatesSame;
};

export const isRateRulesFilled = (name: string, rates: RateRule[]) => {
  return (
    name.trim() !== "" ||
    rates.some(rate => Object.values(rate).some(value => value !== ""))
  );
};

export const validateFields = (field: keyof RateRule, value: string) => {
  let error = "";

  const isValidNumber = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;

  switch (field) {
    case "factor":
    case "amount":
      if (value && !isValidNumber.test(value)) {
        error = "Enter a valid number.";
      } else if (parseFloat(value) <= 0) {
        error = `${capitalize(field)} must be a positive number.`;
      } else if (field === "amount" && value.split(".")[1]?.length > 2) {
        error = "Factor cannot exceed 2 decimal places.";
      } else if (field === "factor" && value.split(".")[1]?.length > 3) {
        error = "Amount cannot exceed 3 decimal places.";
      }
      break;
  }

  return error;
};
