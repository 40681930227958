import React from "react";
import {
  ModalHeader,
  RotaNewModal,
  RotaButtonIcon,
  RotaButtonNew,
  iconNew as RotaIcon
} from "@teamrota/rota-design";
import { Variant } from "@teamrota/rota-design/dist/components/button";
import { Wrapper, Heading, ButtonGroup } from "./styles";

const { Cancel } = RotaIcon;

interface Props {
  onCancel: () => void;
  onClose: () => void;
}

const CancelModal = ({ onCancel, onClose }: Props) => {
  return (
    <RotaNewModal
      size="small"
      onClose={onCancel}
      header={
        <ModalHeader
          endAction={
            <RotaButtonIcon onClick={onCancel}>
              <Cancel />
            </RotaButtonIcon>
          }
        />
      }
    >
      <Wrapper>
        <Heading>Are you sure you want to cancel?</Heading>
        <p>Cancelling takes away all the information you have entered</p>
        <ButtonGroup>
          <RotaButtonNew variant={Variant.outlined} onClick={onCancel}>
            No
          </RotaButtonNew>
          <RotaButtonNew onClick={onClose}>Yes</RotaButtonNew>
        </ButtonGroup>
      </Wrapper>
    </RotaNewModal>
  );
};

export default CancelModal;
