import React, { useState, useEffect, useMemo } from "react";
import {
  ModalHeader,
  RotaNewModal,
  RotaButtonIcon,
  RotaInputNew,
  iconNew as RotaIcon,
  useToast
} from "@teamrota/rota-design";
import BreakRulesForm from "./components/BreakRulesForm";
import CancelModal from "../CancelModal";
import SaveButtons from "../SaveButtons";
import useBreaks from "./hooks/use-breaks";
import { Props, BreakRule, StringMap, BooleanMap } from "./types";
import {
  convertBreakRulesToConfig,
  isBreakRulesFilled,
  isBreakRulesValid,
  isBreakRulesModified,
  isNameTaken,
  validateFields
} from "./utils";
import { InputContainer } from "./styles";

const { Cancel } = RotaIcon;

const CreateEditBreaksModal: React.FC<React.PropsWithChildren<Props>> = ({
  action,
  allSavedBreaks,
  initialBreaks,
  onClose,
  onSave
}) => {
  const {
    name,
    setName,
    breaks,
    resetBreaks,
    handleAddBreak,
    handleUpdateBreak,
    handleDeleteBreak
  } = useBreaks(initialBreaks);

  const [nameError, setNameError] = useState<string>("");
  const [ruleErrors, setRuleErrors] = useState<StringMap>({});
  const [isAddMore, setIsAddMore] = useState<boolean>(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<BooleanMap>({});

  const { showToast } = useToast();

  const isModified = useMemo(
    () => isBreakRulesModified(name, breaks, initialBreaks),
    [name, breaks, initialBreaks]
  );

  useEffect(() => {
    const { isNameValid, isBreaksValid } = isBreakRulesValid(
      name,
      breaks,
      ruleErrors
    );

    setIsAddMore(isBreaksValid);
    setIsSaveEnabled(isNameValid && isBreaksValid && isModified);
  }, [name, breaks, isModified, ruleErrors]);

  const handleDropdownToggle = (key: string) => {
    setIsDropdownOpen(prevState => ({ ...prevState, [key]: !prevState[key] }));
  };

  const handleInputChange = async (
    index: number,
    field: keyof BreakRule,
    value: string
  ) => {
    handleUpdateBreak(index, field, value);
    setRuleErrors(prevErrors => ({
      ...prevErrors,
      [`${field}-${index}`]: validateFields(breaks[index], field, value),
      ...(field === "startDay" && {
        [`endDay-${index}`]: validateFields(
          breaks[index],
          "endDay",
          breaks[index].endDay
        )
      })
    }));
  };

  const handleSave = async (saveAndAdd: boolean) => {
    if (isNameTaken(name, initialBreaks, allSavedBreaks)) {
      setNameError("Name already taken. Choose another.");
      showToast("Correct all errors before saving.", { severity: "error" });
      return;
    }

    const config = convertBreakRulesToConfig(breaks);
    try {
      await onSave({ name, config });
      showToast("Record saved.", { severity: "success" });
      if (saveAndAdd) {
        setName("");
        resetBreaks();
      } else onClose();
    } catch (e) {
      showToast(`Error: ${(e as Error).message}`, { severity: "error" });
    }
  };

  const handleCloseModal = () => {
    if (
      (action === "Create" && isBreakRulesFilled(name, breaks)) ||
      (action === "Edit" && isModified)
    ) {
      setIsCancelModalOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <RotaNewModal
      onClose={handleCloseModal}
      header={
        <ModalHeader
          title={`${action} Breaks`}
          endAction={
            <RotaButtonIcon onClick={handleCloseModal}>
              <Cancel />
            </RotaButtonIcon>
          }
        />
      }
    >
      <InputContainer>
        <RotaInputNew
          label="Name"
          placeholder="Enter a name"
          helperText="Please provide a name"
          value={name}
          onChange={({ target: { value } }) => {
            setName(value);
            setNameError("");
          }}
          isError={!!nameError}
          errorMessage={nameError}
        />
        <BreakRulesForm
          breaks={breaks}
          errors={ruleErrors}
          isAddMore={isAddMore}
          isDropdownOpen={isDropdownOpen}
          onDropdownToggle={handleDropdownToggle}
          onInputChange={handleInputChange}
          onAddBreak={handleAddBreak}
          onDeleteBreak={handleDeleteBreak}
        />
      </InputContainer>

      <SaveButtons
        action={action}
        isEnabled={isSaveEnabled}
        onSave={handleSave}
      />

      {isCancelModalOpen && (
        <CancelModal
          onCancel={() => setIsCancelModalOpen(false)}
          onClose={onClose}
        />
      )}
    </RotaNewModal>
  );
};

export default CreateEditBreaksModal;
